$brand-action: #2A6CA8; // Blue
$brand-main: #288A4F;   // Right Networks Green
$brand-green: #06A646;  // Right Networks Green part II: the revenge
$brand-dark-green: #0B833B;
$danger-red: #C30017;
$rootworks-gray: #2A3690;
$royal-blue: #3F57E8;
$white: #FFFFFF;
$sidebar-width: 210px;
$sidebar-offset: $sidebar-width - 15;
