@import 'variables';

.rn-btn {
  border-radius: 2px;
  box-sizing: border-box;
  font-family: Effra, Roboto, Rubik;
  font-weight: 600;
  text-transform: none;
  color: white;
  line-height: 1.8rem;
  font-size: 13px;
  border: none;
  cursor: pointer;
  height: 28px;

  &:disabled, &[disabled] {
    opacity: 0.38 !important;
  }

  &.rn-btn-sm {
    padding: 5px 10px;
    line-height: 1.5;
    border-radius: 3px;
  }

  &.rn-btn-xs {
    padding: 1px 8px;
    font-size: 11px;
  }
  // orange buttons -- for legacy purposes
  &.btn-primary, &.btn-success {
    background-color: $brand-green;
    border-color: $brand-green;

    &:hover, &:focus, &:active {
      background-color: $brand-green;
      border-color: $brand-green;
    }
  }
  // green log in button
  &.rn-btn-primary {
    background-color: $brand-green;
    border: 2px solid $brand-green;
    color: $white;
    text-transform: capitalize;
    font-family: Effra, Roboto, Rubik;
    font-size: 16px;

    &:hover {
      opacity: 0.8;
      color: $white !important;
    }

    &.login {
      width: 100%;
      height: 38px;
    }

    &.add {
      @extend .small;
      text-transform: uppercase;
      font-size: 13px;
    }

    &.remove {
      @extend .small;
      color: $brand-green;
      background: $white;
      text-transform: uppercase;
      font-size: 13px;

      &:hover {
        color: $brand-dark-green !important;
        border: 1px solid $brand-dark-green !important;
      }
    }
  }
  // call-to-action (primary); blue submit order
  &.btn-action {
    background-color: $brand-action;
    border-color: $brand-action;
    font-weight: bold;
    font-size: 14px;
    padding: 6px 8px;
    height: auto;

    &:hover {
      background-color: #5589b9;
      border-color: #5589b9;
      color: white;
    }

    &:disabled, &[disabled] {
      cursor: not-allowed;
      opacity: 0.65;
      color: #C8C4C4;
      text-transform: none;
      font-weight: bold;
      padding: 6px 8px;
    }
  }
  // dark blue; Edit and Save in User Details panel
  &.btn-secondary-nav {
    @extend .xsmall;
    background-color: #3A4D5B;
    border-color: #3A4D5B;

    &.action {
      width: 60px;
      height: 26px;
    }

    &.modal-save {
      min-width: 148px;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }

    &.modal-option {
      min-width: 148px;
      text-transform: capitalize;
      letter-spacing: 0.8px;
    }
  }
  // application tile actions
  &.rn-btn-inst-app {
    @extend .small;
    background-color: #2a6ca8;
    font-weight: 600;
    text-transform: none;
    color: #feffff;
    padding: 0;
  }

  &.rn-btn-rem-app {
    @extend .small;
    background-color: #feffff;
    box-sizing: border-box;
    font-weight: 600;
    color: #2a6ca8;
    line-height: normal;
    font-size: 13px;
    padding: 0;
    border: 1px solid #2a6ca8;
    border-width: thin;
  }
  // tertiary action; white buttons with blue text and border
  &.rn-btn-tertiary-action {
    background-color: white;
    font-family: Effra, Roboto, Rubik;
    font-size: 13px;
    color: #2a6ca8;
    background-color: #feffff;
    border: 1px solid #2a6ca8;

    &.userDetailAction {
      height: 28px;
    }

    &:hover, &:active {
      background-color: #F4F5F6;
    }

    &:active {
      border-color: #BDBDBD;
    }

    &.small {
      width: 120px;
    }

    &.medium {
      width: 190px;
    }

    &.rnaction-failed {
      color: #c0392b;
    }

    &.rnaction-success {
      color: #008836;
    }
  }
  // navigation
  &.btn-nav {
    @extend .small;
    background-color: #3A4D5B;
    border-color: #3A4D5B;
    text-transform: uppercase;
    font-weight: bold;

    &:hover:not([disabled]), &:active {
      opacity: 0.8;
    }
  }

  &.btn-close {
    @extend .small;
    background-color: #F2F2F2;
    color: #555555;
    text-transform: uppercase;
    font-weight: bold;
  }
  // navigation (secondary)
  &.rn-btn-cancel {
    background-color: #e8e8e8;
    border-color: #e8e8e8;
    color: #555555;
    text-transform: uppercase;
    font-weight: bold;
  }

  &.rn-btn-no-bg {
    background-color: transparent;
    border-color: transparent;
  }
  // dialog buttons
  &.rn-btn-dialog {
    background-color: #425563;
    font-weight: 600;
    text-transform: capitalize;
    padding-top: 4px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 4px;
    border: none;
    border-radius: 2px;
    font-size: 14px;
    letter-spacing: 1px;
    border-width: 2px;
    padding: 7px 24px;
    text-decoration: none;
    height: 42px;
  }

  &.rn-btn:disabled, &.rn-btn[disabled] {
    cursor: not-allowed !important;
  }
  // global header buttons/links; green like Support and Send Feedback
  &.rn-btn-main {
    @extend .medium;
    background-color: $brand-main;
    border-color: $brand-main;
    text-transform: uppercase;
    font-weight: bold;
    height: 34px;

    &.dropdown {
      height: 40px;
    }
  }
  // destructive actions
  &.rn-btn-delete {
    @extend .small;
    background-color: $danger-red;
  }

  &.rn-btn-default {
    color: #333333;
    background-color: #E0E0E0;
    border-color: #E0E0E0;
    padding: 4px 7px;

    &:hover, &:focus, &:active {
      background-color: #BDBDBD;
      border-color: #BDBDBD;
    }
  }
  // sizing
  &.xsmall {
    min-width: 60px;
  }

  &.small {
    min-width: 96px;
  }

  &.modal-nav {
    min-width: 110px;
    height: 34px;
  }

  &.medium {
    min-width: 120px;
  }

  &.medium-plus {
    min-width: 155px;
  }

  &.large {
    min-width: 195px;
  }

  &.rn-btn-success.primary {
    background-color: $brand-green;
    color: #fff;
    font-weight: 600;
    letter-spacing: .65px;

    &:disabled, &[disabled] {
      opacity: 1 !important;
    }
  }

  &.rn-btn-back {
    background-color: #F2F2F2;
    border-color: #F2F2F2;
    border-width: 2px;
    font-family: Effra, Roboto, Rubik;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #555555;
    padding: 4px 30px;
  }

  &.accessMgmt-btn {
    background-color: #425563;
    color: #fff;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: .65px;
    height: 28px !important;
    font-size: 12px !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  &.btn-block {
    display: block;
    width: 100%
  }

  &.btn-danger {
    background-color: #F44336;
    border-color: #F44336
  }

  &.btn-danger:hover, &.btn-danger:focus, &.btn-danger:active {
    background-color: #D32F2F;
    border-color: #D32F2F
  }

  &.btn-default {
    background-color: #E0E0E0;
    border-color: #E0E0E0
  }

  &.btn-default:hover, &.btn-default:focus, &.btn-default:active {
    background-color: #BDBDBD;
    border-color: #BDBDBD
  }

  &.btn-default:active, &.btn-default.active {
    color: #333;
    background-color: #c7c7c7;
    border-color: #adadad
  }

  &.btn-default:active:hover, &.btn-default.active:hover &.btn-default:active:focus, &.btn-default.active:focus, &.btn-default:active.focus, &.btn-default.active.focus {
    color: #333;
    background-color: #b5b5b5;
    border-color: #8c8c8c
  }

  &.btn-addon {
    @extend .small;
    background-color: #4564A2;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 10px 45px 34px 46px;
    border-radius: 6px;
    font-size: 14px;
    text-transform: uppercase;

    &.btn-remove {
      background-color: white !important;
      color: #4564A2 !important;
      border: 1px solid #EEF1F2;
    }
  }
}


.rn-text-link {
  font-family: Arial, Helvetica, sans-serif;
  white-space: normal;
  font-size: 14px;
  color: #1872c4;
  text-decoration: none;
  cursor: pointer;
  font-weight: 400;
  border: none;
  background: inherit;

  &.closeErroredUser {
    font-size: 13px;
    font-weight: 700;
    text-decoration: underline;
    color: #2c6da3;
  }

  &:hover {
    text-decoration: underline;
  }

  &:active {
    font-weight: 600;
  }

  &:disabled, &[disabled] {
    opacity: 0.50;
    cursor: not-allowed;
    text-decoration: none;
    pointer-events: none;
  }
}

.button-spaced {
  margin-left: 20px;
}

.right-spacing {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
  font-size: 14px;
}

.icon-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: 0;
  background: 0 0
}

.icon-button-empty-square {
  border: 1px solid #a1a2a1;
  background-color: transparent;
  padding: 1px !important;
  width: 1.8em;
  height: 1.8em;
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle
}

.btn-group > .btn {
  float: left
}

.btn-group > .btn, .btn-group-vertical > .btn {
  position: relative
}

.btn-group > .btn:hover, .btn-group-vertical > .btn:hover, .btn-group > .btn:focus, .btn-group-vertical > .btn:focus, .btn-group > .btn:active, .btn-group-vertical > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn.active {
  z-index: 2
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
  margin-left: -1px
}

.close-x {
  float: right;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  filter: alpha(opacity=20);
  border: none;
  background-color: inherit;
  padding-top: 8px;
}

.green-add-to-cart-btn {
  display: block;
  width: 120px;
  height: 28px;
  border-radius: 3px;
  background-color: #06a646;
  box-sizing: border-box;
  font-family: Effra, Roboto, Rubik;
  font-weight: 600;
  color: #fff;
  text-align: center;
  line-height: 28px;
  font-size: 12px;
  border: 1px solid #06a646;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-right: 15px
}

.green-add-to-cart-btn:hover {
  background-color: #38B86B !important;
  color: #ffffff !important;
}

.green-add-to-cart-btn:active:focus {
  color: #FFFFFF;
}

.green-add-to-cart-btn:focus {
  background: #38B86B;
  color: #FFFFFF;
  outline: none;
}

.green-add-to-cart-btn.disabled, .green-add-to-cart-btn[disabled] {
  cursor: not-allowed;
  opacity: .36;
}

.btn-modal {
  height: auto;
  font-size: 16px;
}

.btn-modal.disabled, .btn-modal[disabled] {
  opacity: .65;
}

.btn-modal.primary {
  background-color: #4564A2;
  color: #fff;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  border-radius: 6px;

  &.rn-btn-inverse {
    color: #4564A2;
    background-color: #EDF3FF;
  }

  &.rn-btn-back-slider {
    background-color: #E5E5E5;
    color: #666666;
  }
}

.btn-icon {
  height: 36px;
  width: 36px;
  border: 1px solid #D1DAE3;
  border-radius: 4px;
  background-color: #FFF
}

.marg-left-76{
  margin-left: 76%;
}
#btnUninstall > div > button{
  margin: 4px 8px !important;
}

.accordion-next-button-footer {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  display: inline-flex;
  margin-bottom: 10px;
  padding-right: 5px;
}

.continue-button-margin {
  margin: 4px 1px;
}
