@import 'variables';
@import 'filters';

@font-face {
  font-family: 'Effra';
  src: url('../../assets/fonts/Effra Regular.ttf') format('truetype');
}

body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #FFF;
  font-size: 13px;
  color: #000000;
  margin: 0;
  padding: 0;
  height: 100%;
}

rw-header img {
  width: 237px;
  height: 27px;
  position: absolute;
  inset: 24.25% 89.19% 13.67% 1.43%;
}

rw-header i {
  display: none;
}

table th {
  text-align: left;
}

.fa-check-circle {
  font-size: 20px;
  color: #198754;
}

.fa-times-circle {
  color: #FF5340;
  font-size: 20px;
}

.mat-mdc-card {
  background-color: #FFFFFF;
  padding: 20px 20px;
}

// package cards
.package-cards {
  display: flex;
  flex-wrap: wrap;

  &-no-margin {
    margin: -15px -15px;
  }

  .package-card:hover {
    cursor: pointer;
  }

  .package-card-info-only {
    border: 1px solid #BABBBB;
    max-width: 344px;
    min-width: 344px;
  }

  .package-card {
    flex: 1 0 315px;
    box-sizing: border-box;
    margin: 15px 15px;
    border: 1px solid #BABBBB;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    // border-top-left-radius: 12px !important;
    // border-top-right-radius: 12px !important;
    border-radius: 20px !important;
    box-shadow: 0px 1px 4px rgba(174, 174, 174, 0.996078431372549);
    max-width: 315px;
    min-width: 315px;

    &.card-selected {
      border-color: #2A3690;
    }

    &.disabled {
      background-color: #f2f2f2;
      opacity: .3;
      cursor: not-allowed
    }

    .card-product-header {
      background-color: #E2F2E8;
      padding: 16px;
      border-bottom: 1px solid #BABBBB;

      .md-package-headline {
        font-family: 'Open Sans SemiBold','Open Sans Regular','Open Sans';
        font-size: 17px;
        color: #0a110d;
      }
    }

    .md-card-content {
      padding: 20px 16px;

      .packagePriceHeading {
        font-size: 15px;
        font-weight: bold;
      }
    }

    .card-product-footer {
      margin: 8px 16px;
      padding: 19px 0;
      display: flex;
      justify-content: space-between;
    }

    .card-product-footer-selectable {
      margin: 8px;
      padding: 19px 0;
      display: flex;
      justify-content: center;
    }
  }
}

.selected {
  border: 4px #32AA54 !important;
  box-shadow: 0px 0px 0px 5px #1872c4 !important;
}

.selectable:hover:not(.selected) {
  box-shadow: 0px 0px 0px 2px rgba(174, 174, 174, 0.996078431372549);
}

.selectable {
  cursor: pointer;
}

.confirmDialogFooter {
  height: auto;
}

.confirmDialogFooterCancelDiv {
  width: 50%;
  padding-left: 2px;
  padding-right: 2px;
  height: 42px;
}

.confirmDialogFooterConfirmDiv {
  width: 50%;
  padding-left: 2px;
  padding-right: 2px;
  height: 42px;
}

.confirmDialogContent > div.ui-dialog-content {
  background-color: #212121;
  color: #F5F5F5;
  border-color: #212121
}

.confirmDialogContent > h3.modal-header-text {
  color: #F5F5F5 !important;
}

.confirmDialogContent > h3.modal-header-text-black {
  color: #000 !important;
}

.confirm-modal-content {
  background-color: #212121;
}

// primeng overrides
.ui-dropdown-label-container {
  overflow: hidden;
}

.ui-tooltip,
.p-tooltip {
  z-index: 2147483647 !important;
}

.ui-tooltip ul,
.p-tooltip ul {
  margin-bottom: 0 !important;
}

.ui-tooltip.passReq .ui-tooltip-text div,
.p-tooltip.passReq .p-tooltip-text div {
  margin-bottom: -2rem;
}

.ui-tooltip.fit-content .ui-tooltip-text,
.p-tooltip.fit-content .p-tooltip-text {
  width: fit-content
}

.ui-tooltip.wideTooltipSm .ui-tooltip-text,
.p-tooltip.wideTooltipSm .p-tooltip-text {
  width: 250px;
}

.ui-tooltip.wideTooltipMed .ui-tooltip-text,
.p-tooltip.wideTooltipMed .p-tooltip-text {
  width: 420px;
}

.ui-tooltip.wideTooltipLg .ui-tooltip-text,
.p-tooltip.wideTooltipLg .p-tooltip-text {
  width: 640px;
}

.ui-tooltip.dark .ui-tooltip-text,
.p-tooltip.dark .p-tooltip-text {
  background: #22282A;
  color: white;
}

.ui-tooltip.wideTooltipMed, .ui-tooltip.wideTooltipLg .ui-tooltip-text,
.p-tooltip.wideTooltipMed, .p-tooltip.wideTooltipLg .p-tooltip-text {
  p {
    margin-bottom: 0;

    &:last-child {
      margin-bottom: 1em;
    }
  }
}

// toast overrides
p-toast {
  p {
    font-weight: bold;
    padding: 5px;
  }
}

.p-toast {
  width: fit-content !important;
}

.ui-toast-message-error ,
.p-toast-message-error {
  background-color: $danger-red !important;
  color: $white !important;
  padding: 5px;
  
  a, button {
    cursor: pointer;
    color: #2c6da3;
    text-decoration: none;
    white-space: nowrap;

    &.ui-toast-close-icon ,
    .p-toast-icon-close-icon {
      color: $white !important;
      opacity: 0.75;
    }
  }
}

.ui-toast-message-success,
.p-toast-message-success {
  background-color: #3F57E8 !important;
  color: $white !important;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;

  a, button {
    cursor: pointer;
    color: #2c6da3;
    text-decoration: none;
    white-space: nowrap;

    &.ui-toast-close-icon,
    .p-toast-icon-close-icon {
      color: $white !important;
      opacity: 0.75;
    }
  }
}

.userRemoveIcon {
  font-size: 1.5em;
  color: #787878;
}

.preDiscountedPrice {
  color: #777777;
  text-decoration: line-through;
}

.shoppingCartIcon {
  transform: scale(2.0);
}

.fa-stack[data-count]:after {
  position: absolute;
  right: 0%;
  top: 0%;
  content: attr(data-count);
  font-size: 60%;
  padding: .6em;
  border-radius: 999px;
  line-height: .55em;
  color: white;
  text-align: center;
  min-width: 2em;
  font-weight: bold;
  background: #3F57E8;
  border-style: solid;
  border: 1px solid white;
  box-shadow: 0 0 2px #3F57E8;
}

.fa-md {
  font-size: .75em;
}

.fa-xl {
  font-size: 1.5em;
}

.package-card-info-content-style {
  padding: 10px 0 5px 20px;
  font-family: 'Open Sans Regular','Open Sans';
}

.md-package-headline {
  font-family: 'Open Sans SemiBold','Open Sans Regular','Open Sans';
  font-size: 17px;
  color: #0a110d;
}

.totalsLightBlue {
  background-color: #ECEEFD;
  text-align: right;

  span {
    padding: 10px;
    display: block;
    line-height: 13px;
  }

  hr {
    margin: 0px;
    color: #aaa;
  }
}

.infoIcon {
  margin-top: 1px;
  font-size: 16px
}

.box-size {
  width: 450px;
  box-sizing: border-box;
  margin: 15px;
  border: 1px solid #BABBBB;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(174,174,174,.99607843);
}

.panel-body {
  padding: 0 15px 15px !important;
}

.footer-divider {
  border-top: 1px solid #BABBBB;
  margin-left: 25px;
  margin-right: 25px;
}

.body-text {
  font-family: 'Open Sans SemiBold','Open Sans Regular','Open Sans';
  font-weight: 600;
  font-style: normal;
  color: #555;
}

input[type='radio'] {
  transform: scale(2);
  margin-right: 10px;
}
input[type='checkbox']{
  accent-color: #3F57E8;
}
.failedToAdd {
  background-color: #EDC8C4 !important;
}

.successTrans {
  animation-name: colorChange;
  animation-duration: 6s;
}

@keyframes colorChange {
  0% {
    background-color: #E1FAEA;
  }

  100% {
    background-color: #E1FAEA !important;
  }
}


body .ui-inputtext.mask-edit-field {
  font-size: 16px;
}

.org-tree-search {
  width: 100%;
  float: left;
}

.org-tree-search-edit {
  float: right;
  width: 100%;
}

a.disabled, a.disabled:hover {
  color: gray;
  cursor: not-allowed;
  text-decoration: none;
}

.events-search-magnify {
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -10px;
}

.date-field-style {
  width: 220px;
}

.shopping-cart-quantity-control-btn {
  width: 20px;
  height: 20px;
  padding-left: 2.5px !important;
  padding-top: 1px !important;
}

.shopping-cart-quantity-control-btn > i {
  padding-top: 0px !important;
}

.shopping-cart-quantity-control-input {
  margin-left: 0px !important;
  margin-right: 0px !important;
  font-size: inherit !important;
}

.circledNumberedList {
  counter-reset: li;
  list-style-type: none;
  padding-left: 4px;
  font-family: Effra;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  width: 84%;

  li {
    position: relative;
    padding: 10px 0 5px 30px;

    &:before {
      content: counter(li);
      counter-increment: li;
      font-size: 13px;
      height: 24px;
      width: 24px;
      border: 1px solid #B9C3CD;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      left: -3px;
      top: 5px;
      vertical-align: middle;
      color: #657881;
      font-weight: 600;
      padding-top: 2px;
    }
  }
}

.fa-spinner-third {
  color: #4b5a9b;
  font-size: 3em;
}

.fa-adjust {
  position: relative;
  top: 3.5px;
}

.fa-adjust-chevron {
  position: relative;
  top: -0.5px;
  left: -1px;
}

// svg styles

button.mat-mdc-menu-item {
  img {
    margin-right: 8px;
    @extend .filter-default;
  }
}

.spinner-menu-size {
  height: 18px;
  width: 18px;
}

.loading-spinner {
  animation: loading-spinner 2s linear infinite;

  &.white-icon {
    filter: brightness(0) invert(1);
    max-height: 14px !important;
  }

  &.gray-icon {
    filter: invert(47%) sepia(8%) saturate(775%) hue-rotate(153deg) brightness(96%) contrast(93%);
    max-height: 14px !important;
  }

  &.blue-icon {
    filter: invert(37%) sepia(68%) saturate(389%) hue-rotate(180deg) brightness(92%) contrast(97%);
    max-height: 100px !important;
  }

  &.royal-icon {
    filter: invert(28%) sepia(96%) saturate(1467%) hue-rotate(219deg) brightness(91%) contrast(100%);
    max-height: 100px !important;
  }
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.login-error-icon {
  position: absolute;
  left: 11.25px;
  top: 16.25px;
  width: 13.5px;
  height: 13.5px;
  filter: invert(12%) sepia(99%) saturate(4022%) hue-rotate(354deg) brightness(88%) contrast(107%);
}

.sharp-angles-error {
  border: 1px solid #c30017;
}

.sidenav-tooltip-right {
  margin-left: 25px !important;
  margin-top: 15px !important;
}
.tooltipMsg >div{
  background-color: #141C44 !important;
  font-size: 12px;
  border-radius: 4px ;
  color: #FFF !important;
  font-weight: 700;
}
select.ui-inputtext {
  -webkit-appearance: menulist-button !important;
  -moz-appearance: menulist-button !important;
  appearance: menulist-button !important;
}

.sidebar-scrollable-content {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  height: 85%
}

.textbox-sharp-angles {
  width: 100%; //width has been changed due to the longer error pessgae as per shannon
  height: 41px; //todo put width and height in another way?
  border: 1px solid rgba(100, 100, 100, 0.15);
  border-radius: 8px;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  color: #0a110d;
  text-align: left;
  line-height: normal;
  //padding of text inside box
  padding-left: 8px;
  //margin between next inline element
  margin-right: 8px;
  font-size: 14px;
  //on hover blue
}

ul.action-list {
  margin-bottom: 1.5rem;
}

ul.action-list li {
  margin: 5px 0px;
}

.basic-container {
  padding: 5px;
}

.version-info {
  font-size: 8pt;
  float: right;
}
.withoutMarginDropdown > div > .ui-dropdown-label-container{
  margin-left: 0px !important;
}
.qboAccordionTab > .ui-accordion-header > a{
  background-color: #FFF !important;
  border: 0px !important;
}

.qboAccordionTab > .ui-accordion-header > a:hover {
  text-decoration: none !important;
}

.package-content > ul > p{
  margin-left: 10px !important;
}

.package-content > ul > li{
  margin-left: 32px !important;
}

.custom-menu{
  border: 1px solid rgba(100, 100, 100, 0.15) !important;
  border-radius: 4px !important;
  padding: 4px !important;
}

.tab-title {
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.80);
}
