// general purpose formatting styles

// alignment
.ai-center {
  align-items: center;
}

// color
.color-black {
  color: #000000;
}

.color-green {
  color: #2fa351;
}

.color-green-enabled {
  color: #34B55A;
}

.color-red {
  color: #c00909;
}

// cursor
.c-pointer { 
  cursor: pointer;
}

.c-default {
    cursor: default !important;
}

// display
.dspy-none {
  display: none;
}

.dspy-block {
  display: block;
}

.dspy-iblock {
  display: inline-block;
}

.dspy-flex {
  display: flex;
}

.dspy-inline {
  display: inline;
}

// flex
.flex-column {
  flex-direction: column;
}

// margin-left
.m-l-8 {
  margin-left: 8px;
}

// margin-top
.m-t-neg9 {
    margin-top: -9px;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-33 {
  margin-top: 33px;
}

// margin-bottom
.m-b-1rem {
  margin-bottom: 1rem !important;
}

.m-b-6 {
  margin-bottom: 6px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-32 {
  margin-bottom: 32px;
}

.no-bottom-margin {
  margin-bottom: 0px !important;
}

.no-h-pad {
  padding-left: 0px !important;
  padding-right: 0px !important;
  color: #333333;
}

.label-style {
  font-size: 18px
}

.detailsLink, .blueInfo {
  color: #337ab7;
  text-decoration: none;
  font-weight: 400;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

// hr
hr.small {
  margin-top: 10px;
  margin-bottom: 10px;
}

// PrimeNG overrides
:root {
  --layer-0: #ffffff;
  --layer-1: #f4f4f4;
  --layer-2: #eaeaea;
  --text-color: #333333;
  --text-color-secondary: #848484;
  --primary-color: #770000;
  --primary-color-text: #ffffff;
}

body .ui-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled).ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #007ad9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-datepicker table td > a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-chkbox .ui-chkbox-box.ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #007ad9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-chips > ul.ui-inputtext:not(.ui-state-disabled).ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #007ad9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #007ad9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-inputswitch.ui-inputswitch-focus .ui-inputswitch-slider {
  background: #b7b7b7;
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-inputtext:enabled:focus:not(.ui-state-error),
body .p-inputtext:enabled:focus:not(.p-state-error) {
  outline: 0 none;
  outline-offset: 0;
  border-color: #3F57E8;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-listbox .ui-listbox-list .ui-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-multiselect:not(.ui-state-disabled).ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #007ad9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #007ad9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-rating:not(.ui-state-disabled):not(.ui-rating-readonly) a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  z-index: 1;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-slider .ui-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-button:enabled:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-button.ui-state-default.ui-button-secondary:enabled:focus, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-button.ui-state-default.ui-button-info:enabled:focus, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-button.ui-state-default.ui-button-success:enabled:focus, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-button.ui-state-default.ui-button-warning:enabled:focus, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-button.ui-state-default.ui-button-danger:enabled:focus, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-dataview .ui-dataview-layout-options .ui-button:focus {
  outline: 0 none;
  outline-offset: 0px;
  z-index: 1;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .fc .fc-event:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-orderlist .ui-orderlist-list .ui-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-paginator .ui-paginator-first:focus,
body .ui-paginator .ui-paginator-prev:focus,
body .ui-paginator .ui-paginator-next:focus,
body .ui-paginator .ui-paginator-last:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-picklist .ui-picklist-list .ui-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-table .ui-sortable-column:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-treetable .ui-sortable-column:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-accordion .ui-accordion-header a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-fieldset .ui-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li .ui-tabview-close:focus, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li .ui-tabview-close:focus, body .ui-tabview.ui-tabview-left .ui-tabview-nav li .ui-tabview-close:focus, body .ui-tabview.ui-tabview-right .ui-tabview-nav li .ui-tabview-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li:focus, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:focus, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:focus, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-overlaypanel .ui-overlaypanel-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-fileupload-choose.ui-state-focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-breadcrumb ul li .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-contextmenu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-megamenu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-menu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-menubar .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-panelmenu .ui-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-slidemenu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-steps .ui-steps-item .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-tieredmenu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-messages .ui-messages-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-inplace .ui-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}


.ui-table-loading {
  opacity: 0.5;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover {
  border: none;
  background-color: #dbdbdb;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a {
  color: #657881;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close {
  color: #657881;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li, body .ui-tabview.ui-tabview-left .ui-tabview-nav li, body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  border: none;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a,
.p-tabview-nav a {
  float: none;
  display: inline-block;
  color: black;
  padding: 0.571em 1em;
  font-weight: 400;
  font-size: 14px;
  background-color: #ffffff;
  border-bottom: 1px #333333;
  text-decoration: none !important;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: #333333;
  color: #ffffff;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover {
  border-left: none;
  border-right: none;
  border-top: none;
}

.userDemo {
  padding: 0;
  font-family: Arial, Helvetica, sans-serif !important;
}


body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-tabview-selected a,
.p-tabview-nav li.p-highlight a {
  cursor: pointer;
  border-bottom: 4px solid #3F57E8 !important;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
  background-color: #007ad9;
  border: none;
}

body .ui-tabview.ui-tabview-top.fullTabPanel {
  width: 100%;
}

body .ui-tabview.userDemo .ui-tabview-panels {
  padding: 0px;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li.ui-state-active {
  background-color: #ffffff;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li.ui-state-active:hover {
  background-color: #ffffff;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li.ui-state-active a, .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a {
  color: black;
  font-size: 14px;
  font-weight: 400;
  padding-left: 2PX;
  padding-right: 2px;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li.ui-state-active:hover a, .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a {
  color: #272727;
  font-size: 14px;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav.userDemo li.ui-state-active, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
  background-color: none;
}

.faMedium {
  font-size: 1.75em;
}

.p-t-xs {
  padding-top: 5px;
}

.p-t-sm {
  padding-top: 10px;
}

.p-t-md {
  padding-top: 15px;
}

.p-t-lg {
  padding-top: 20px;
}

.p-t-xl {
  padding-top: 25px;
}

.p-b-sm {
  padding-bottom: 10px;
}

.m-t-xs {
  margin-top: 5px
}

.m-t-sm {
  margin-top: 10px
}

.m-t {
  margin-top: 15px
}

.m-t-md {
  margin-top: 20px
}

.m-t-lg {
  margin-top: 30px
}

.m-t-xl {
  margin-top: 40px
}

.m-t-xxl {
  margin-top: 50px
}

.m-t-xxxl {
  margin-top: 60px
}

.padding-20-all {
  padding: 20px 20px 20px 20px;
}

.no-padding {
  padding: 0px;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-top {
  padding-top: 0px;
}

.p-top-10 {
  padding-top: 10px;
}

.p-top-20 {
  padding-top: 20px;
}

.p-right-50 {
  padding-right: 50px;
}

.p-left-33 {
  padding-left: 33px;
}

.p-bottom-33 {
  padding-bottom: 33px;
}

.p-top-8 {
  padding-top: 8px;
}

.p-top-30 {
  padding-top: 30px;
}

.p-top-58 {
  padding-top: 58px;
}

.p-left-5{
  padding-left: 5px;
}

.p-right-33 {
  padding-right: 33px;
}

.l-align {
  text-align: left;
}

.c-align {
  text-align: center !important;
}

.r-align {
  text-align: right !important;
}

.v-align-top {
  vertical-align: top;
}

.v-align-middle {
  vertical-align: middle;
}

.p-r-8 {
  padding-right: 8px !important;
}

.margin-l-13 {
  margin-left: 13px;
}

.ws-normal {
  white-space: normal;
}

.ws-nowrap {
  white-space: nowrap;
}

.margin-4 {
  margin: 4px;
}

.margin-10 {
  margin: 10px;
}

.lmargin-auto {
  margin-left: auto;
}

.m-bt-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.m-b-11 {
  padding-bottom: 11px;
}

.m-t-14 {
  margin-top: 14px;
}

.text-right {
  text-align: right
}

.extra-small-font {
  font-size: x-small;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-20 {
  margin-bottom: 20px;
}

// justified content
.justifiedTitlebar, .justifiedRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.justifiedRow {
  -ms-flex-direction: row;
  flex-direction: row
}

.justifiedCenter {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center
}

.justifiedFlexEnd {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

// flex
.f1 {
  flex: 1;
}

.pageTitle {
  font-family: Effra, Roboto, Rubik;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sectionTitle {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}

.packages-subtext {
  font-weight: 400;
  margin: 5px 0;
  font-size: 14px;
  color: #000000;
}

.rnTable-border {
  border: 1px solid #d7d7d7;
}

.float-right {
  float: right !important;

  .row > & {
    margin-left: auto !important;
  }
}

.rightAlignButtons {
  flex-direction: row;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  place-content: center flex-end;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
}

.direct-inventory-table-header {
  font-family: Effra, Roboto, Rubik;
  font-weight: 600 !important;
  padding: 8px !important;
}

.wlp-inventory-table-header {
  font-family: Effra, Roboto, Rubik;
  background-color: #F9F9F9 !important;
  font-weight: 600 !important;
  padding: 10px !important;
}

.wlp-inventory-table-row {
  background-color: #DEEDF7 !important;
  padding: 10px !important;
}

.separator {
  width: 1px;
  background-color: grey;
  height: 30px;
  float: right;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 4px;
}

.less-margin-bottom {
  margin-bottom: 8px;
}

.less-grey-padded {
  background-color: #fcfafa;
  padding: 8px;
}

.no-side-margins {
  margin-left: 0px;
  margin-right: 0px;
}

.table-border {
  border: 1px solid #d7d7d7;
}

.no-top-table-border {
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #d7d7d7;
}

.cdk-overlay-container {
  z-index: 9999999999 !important;
}

.has-pending-validation {
  label {
    color: #3F57E8 !important;
  }

  .help-block {
    margin: 0 !important;
    background-color: #3F57E8 !important;
    color: white !important;
    padding: 1px 3px !important;
  }

  a {
    color: #3F57E8 !important;
    text-decoration: underline !important;
  }

  input {
    border-color: #3F57E8 !important;
  }
}

.align-end {
  display: flex;
  justify-content: flex-end;
}

.pading-25-all-cream-background {
  background: #EEF1F2;
  padding: 25px;
}

.fullScreenRow {
  background: #EEF1F2;
}

.border-bottom-faded {
  border-bottom: 1px solid #e5e5e5;
}

.disp-inl-flex {
  display: inline-flex;
}

.step-desc {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 22px;
}

.weight-600 {
  font-weight: 600;
}

.red-help-block {
  width: 374px; //width has been changed due to the longer error pessgae as per shannon
  height: 26px;
  background-color: #c30017;
  box-sizing: border-box;
  font-family: Effra, Roboto, Rubik;
  font-weight: 700;
  color: #ffffff;
  text-align: left;
  line-height: 2.3;
  margin-top: -16px;
  margin-bottom: -10px;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 11px;
}

.subTitleText {
  box-sizing: border-box;
  font-family: Effra, Roboto, Rubik;
  font-weight: 600;
  color: #555555;
  text-align: left;
  line-height: normal;
}

.black {
  color: black;
}

.border-bottom-darker {
  border-bottom: 1px solid #aaaaaa;
}

.m-l-neg8 {
  margin-left: -8px;
}

.m-l-neg15 {
  margin-left: -15px;
}

.rn-blackText {
  color: #000;
}

// font
.ff-opensans {
  font-family: 'Open Sans' !important;
}

.ff-effra {
  font-family: 'Effra' !important;
}

// margin
.marg-l-25 {
  margin-left: 25px;
}

.marg-t-5 {
  margin-top: 5px;
}

.marg-bot-0 {
  margin-bottom: 0px !important;
}

.marg-bot-30 {
  margin-bottom: 30px;
}

.marg-top-30 {
  margin-top: 30px;
}

// padding
.pad-bot-0 {
  padding-bottom: 0px;
}

.pad-bot-10 {
  padding-bottom: 10px;
}

.pad-bot-35 {
  padding-bottom: 35px
}

.pad-bot-50 {
  padding-bottom: 50px;
}

.pad-bot-75 {
  padding-bottom: 75px;
}

.pad-top-10 {
  padding-top: 10px;
}

.pad-top-30 {
  padding-top: 30px;
}

// position
.position-abs {
    position: absolute;
}

.ta-center {
  text-align: center;
  padding-top: 90px;
}

.overlay-lower-right {
  position: relative;
  bottom: -5px;
  left: 7px;
}

.overlay-lower-right-small {
  position: relative;
  bottom: -1px;
  left: 4px;
}

.width-10 {
  width: 10%;
}

.width-99 {
    width: 99%;
}

.width-15px {
  width: 15px;
}

.width-40 > div {
  width: 40% !important;
}

.license-card {
  width: 339px;
  cursor: pointer !important;
}

.license-card-headline {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}

.license-card-desc {
  padding-left: 35px;
  padding-right: 57px;
}

.license_image {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.shopping-cart-cell {
  font-size: 14px;
}

table.top-border-not-first-row > tbody > tr:first-child > td {
  border-top: none;
}

table.top-border-not-first-row > tbody > tr:not(:first-child) > td {
  border-top: 1px solid #dbdbdb;
}

div.default-header-count > span {
  color: #657881;
}


input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}

::-ms-reveal {
  display: none !important;
}

.classNoReveal::-ms-reveal {
  display: none !important
}

.login-field-input::-ms-clear,
.login-field-input::-ms-reveal {
  display: none !important;
}


.login-field-input[_ngcontent-nlp-c15] {
  border: 1px solid #D1DAE3;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px !important;
  background-color: rebeccapurple;
}

.client-access-treeview-readonly .ui-tree {
  border: none !important;
}

.client-access-treeview-readonly .ui-tree-toggler {
  display: none !important;
}

.cancel {
  color: #3F57E8;
  letter-spacing: -0.02em;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3F57E8;;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.ui-inputtext-notallowed {
  cursor: not-allowed;
}

.max-height-unset {
  max-height: unset !important;
}

.width-95 {
  width: 95% !important;
}

@media only screen and (max-width: 798px) {
  /* For mobile phones: */
  .disp-inl-flex {
    margin-top: 60px;
    
  }
}
